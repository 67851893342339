html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-size: 62.5%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

img {
  max-width: 100%;
}
