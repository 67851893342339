.staff-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 5rem;
}

.staff-links__link {
  display: block;
  flex-basis: 300px;
  background-color: rgba(255, 255, 255, 0.7);
  border: 2px solid rgba($colour-secondary, 0.4);
  padding: 1.2em 1em;
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
  margin: 1rem;
  text-decoration: none;

  &:hover,
  &:focus {
    .staff-links__heading {
      transform: translateY(50rem);
    }

    .staff-links__logo {
      opacity: 1;
    }

    .staff-links__button {
      transform: translate(-50%, -21.5rem);
    }
  }

  &:active .staff-links__button {
    transform: translateY(85%);
  }
}

.staff-links__logo {
  max-height: 50px;
  width: auto;
  margin-top: 0.5rem;
  opacity: 0.6;
  transition: opacity 0.3s ease-in-out;

  .staff-links__link--firefly & {
    opacity: 0.7;
  }

  .staff-links__link--website & {
    opacity: 0.8;
  }
}

.staff-links__heading {
  color: $colour-secondary-dark;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 1.6rem;
  margin-bottom: 0;
  transition: transform 0.3s ease-in-out;

  &::before {
    content: '';
    display: block;
    width: 80%;
    height: 2px;
    margin: 2rem auto 1.9rem;
    background-image: radial-gradient(rgba($colour-secondary, 0.4), transparent 80%);
  }
}

.staff-links__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.staff-links__button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -20rem;
  left: 50%;
  transform: translate(-50%, 0);
  transition: transform 0.2s ease-in-out;
  padding: 1rem;
  background-color: $colour-primary;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  font-style: normal;
  font-size: 1.8rem;
  color: #fff;
  text-transform: uppercase;
}
