$colour-primary: hsl(41, 100%, 48%);
$colour-primary-light: hsl(41, 100%, 80%);
$colour-primary-dark: hsl(41, 100%, 30%);

$colour-secondary: hsl(110, 36%, 63%);
$colour-secondary-light: hsl(110, 36%, 70%);
$colour-secondary-dark: hsl(110, 36%, 40%);

$colour-grey-100: hsl(210, 17%, 95%);
$colour-grey-200: hsl(204, 13%, 92%);
$colour-grey-300: hsl(210, 14%, 89%);
$colour-grey-400: hsl(213, 13%, 83%);
$colour-grey-500: hsl(210, 11%, 71%);
$colour-grey-600: hsl(208, 7%, 55%);
$colour-grey-700: hsl(208, 8%, 41%);
$colour-grey-800: hsl(208, 8%, 31%);
$colour-grey-900: hsl(210, 10%, 23%);
