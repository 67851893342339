body {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 1.6rem;
  padding: 2rem;
  background-image: linear-gradient(#fff, rgba(255, 255, 255, 0.7)),
    linear-gradient(white 250px, transparent), url('../images/background.jpg');
  background-size: 100%, 100%, 80% auto;
  background-position: center, center, calc(50% + 2rem) bottom;
  background-repeat: no-repeat;
  overflow-wrap: break-word;
  word-wrap: break-word;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;

  @media (min-width: 700px) {
    padding: 3rem;
  }

  @media (min-width: 750px) {
    background-size: 100%, 100%, auto 65%;
  }
}

.wrapper {
  text-align: center;
}

.dhsfg-logo {
  fill: $colour-primary;
  max-width: 100px;
}

.site-title {
  font-size: 3rem;
  font-weight: 300;
  text-transform: uppercase;
  color: $colour-primary;
  margin: 4rem 0;
  letter-spacing: 0.2em;
}

.sub-heading {
  font-size: 2.5rem;
  font-weight: 300;
  text-transform: uppercase;
  color: $colour-grey-600;
  margin: 4rem 0;
  letter-spacing: 0.2em;
}

.social-heading {
  font-size: 1.6rem;
  color: $colour-secondary-dark;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.social-icons {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
}

.social-icons__item {
  padding: 0.5rem;
}

.social-icons__link {
  display: block;

  &:hover .social-icons__circle,
  &:focus .social-icons__circle {
    transform: rotate(360deg);
  }
}

.social-icons__circle {
  background-color: #fff;
  padding: 1rem;
  border-radius: 50%;
  width: 4.5rem;
  height: 4.5rem;
  position: relative;
  display: block;
  transition: transform 0.3s ease-in-out;
}

.social-icons__circle svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  fill: $colour-secondary-light;
  width: 50%;
}
